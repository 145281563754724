import { render } from "./MembersSetting.vue?vue&type=template&id=55d47d08"
import script from "./MembersSetting.vue?vue&type=script&lang=ts"
export * from "./MembersSetting.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QSelect,QBtn,QSpace,QForm,QInput});
