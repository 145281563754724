
import InvitationApi from '@/components/invitation/invitation-api'
import { Vue, Options } from 'vue-class-component'
import { IInvitationRequest } from '@/components/invitation/invitation-model'
import { ACTION_INVITATION } from '@/components/invitation/invitation-actions'

@Options({
  components: {},
})
export default class WorkspaceMemberMixin extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  async getInvitationInfoByToken(token: string) {
    return InvitationApi.infoByToken(token)
  }

  async doJoinByToken(token: string) {
    return InvitationApi.joinByToken(token)
  }

  async getInvitedEmails() {
    this.$store.dispatch(ACTION_INVITATION.LOAD_ITEMS, { workspaceId: this.workspaceId })
  }

  async sendInvitation(input: IInvitationRequest) {
    return this.$store.dispatch(ACTION_INVITATION.ADD, { input, workspaceId: this.workspaceId })
  }

  async deleteInvitation(id: string) {
    return this.$store.dispatch(ACTION_INVITATION.DELETE, { id })
  }
}
