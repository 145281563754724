
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ROLES_SELECT } from '@/constants/vars'
import { isValidEmail } from '@/utils/validators'
import { EUserRole, IUserWithPermission } from '@/components/user/user-model'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { InvitationModel } from '@/components/invitation/invitation-model'

import NotFound from '@/components/common/NotFound.vue'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'
import WorkspaceMemberMixin from '@/components/workspace/mixins/WorkspaceMemberMixin.vue'
import logging from '@/utils/logging'
import QForm from 'quasar/src/components/form/QForm.js';

@Options({
  components: {
    NotFound,
  },
  directives: { maska },
})
export default class MembersSetting extends mixins(WorkspaceMixin, WorkspaceMemberMixin) {
  selected = {}
  selectedInvitation = {}
  ROLES_SELECT = ROLES_SELECT

  form = {
    role: EUserRole.member,
    emails: '',
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get members(): IUserWithPermission[] {
    return this.workspace?.members || []
  }

  get sentInvitations(): InvitationModel {
    return this.$store.getters.invitations
  }

  get arrEmails() {
    return this.form.emails.split(/[\n,]/).map(email => email.trim())
  }

  validateEmails() {
    if (!this.form.emails) {
      return true
    }

    for (const email of this.arrEmails) {
      if (!isValidEmail(email)) {
        return false
      }
    }

    return true
  }

  async onDelete(user: IUserWithPermission) {
    console.log(user)
    this.$q.notify({
      type: 'positive',
      message: 'Comming soon!',
    })
  }

  async onDeleteInvited(item: InvitationModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        await this.deleteInvitation(item._id || '')
        await this.getInvitedEmails()
      })
  }

  onSendInvitation() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.sendInvitation({
          role: this.form.role,
          emails: this.arrEmails,
        })

        this.form.emails = ''
        this.getInvitedEmails()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async created() {
    this.$meta.setMeta({ title: ['Members'] })
    await this.getWorkspace(this.workspaceId)
    await this.getInvitedEmails()
  }
}
