<template>
  <section class="q-pa-md">
    <div class="row no-wrap">
      <div>
        <h1 class="text-h4">{{ workspace.title }}</h1>
        <p class="text-subtitle2">Workspace members setting or invite new member into this workspace</p>
      </div>
    </div>

    <div class="container-smaller q-mt-lg">
      <q-list bordered separator class="rounded-borders bg-grey-1">
        <q-item v-for="user in members" :key="user._id">
          <q-item-section>
            {{ user.displayName }}
          </q-item-section>
          <q-item-section side>
            <div class="q-gutter-sm">
              <q-select
                outlined
                dense
                options-dense
                v-model="user.role"
                disable
                :options="ROLES_SELECT"
                emit-value
                map-options
              />
            </div>
          </q-item-section>
          <!-- <q-item-section side>
          <div class="q-gutter-sm">
            <q-btn size="sm" color="red" round icon="delete" @click="onDeleteMember(user)" />
          </div>
        </q-item-section> -->
        </q-item>
      </q-list>
    </div>

    <div class="container-smaller q-mt-lg" v-if="workspace.canWrite">
      <h3 class="text-subtitle2">Invite users by adding their email addresses below.</h3>
      <q-space class="q-my-md" />
      <q-form ref="formRef">
        <q-select
          style="max-width: 130px"
          outlined
          dense
          options-dense
          v-model="form.role"
          label="Role"
          :options="ROLES_SELECT"
          emit-value
          map-options
        />

        <q-space class="q-my-md" />

        <q-input
          :rules="[() => validateEmails() || 'Please input your valid email']"
          type="textarea"
          outlined
          dense
          v-model="form.emails"
          placeholder="member@example.com"
        />

        <p class="text-grey-6 q-my-md">Please add email addresses one per line or separated by commas.</p>

        <q-space class="q-my-md" />
        <q-btn
          color="primary"
          :disable="!form.emails"
          icon="send"
          @click.prevent="onSendInvitation"
          label="Send Invitation"
        />
      </q-form>

      <q-space class="q-my-md" />
      <h3 class="text-subtitle2">Invited emails.</h3>
      <q-list bordered dense separator class="rounded-borders bg-grey-1">
        <q-item v-for="(item, index) in sentInvitations" :key="index">
          <q-item-section>
            {{ item.email }}
          </q-item-section>
          <q-item-section side>
            <div class="q-gutter-sm">
              <q-btn size="xs" flat round icon="close" @click="onDeleteInvited(item)" />
            </div>
          </q-item-section>
        </q-item>
      </q-list>

      <p class="text-grey-6 q-mt-sm" v-if="!sentInvitations.length">No data</p>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ROLES_SELECT } from '@/constants/vars'
import { isValidEmail } from '@/utils/validators'
import { EUserRole, IUserWithPermission } from '@/components/user/user-model'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { InvitationModel } from '@/components/invitation/invitation-model'

import NotFound from '@/components/common/NotFound.vue'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'
import WorkspaceMemberMixin from '@/components/workspace/mixins/WorkspaceMemberMixin.vue'
import logging from '@/utils/logging'
import { QForm } from 'quasar'

@Options({
  components: {
    NotFound,
  },
  directives: { maska },
})
export default class MembersSetting extends mixins(WorkspaceMixin, WorkspaceMemberMixin) {
  selected = {}
  selectedInvitation = {}
  ROLES_SELECT = ROLES_SELECT

  form = {
    role: EUserRole.member,
    emails: '',
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get members(): IUserWithPermission[] {
    return this.workspace?.members || []
  }

  get sentInvitations(): InvitationModel {
    return this.$store.getters.invitations
  }

  get arrEmails() {
    return this.form.emails.split(/[\n,]/).map(email => email.trim())
  }

  validateEmails() {
    if (!this.form.emails) {
      return true
    }

    for (const email of this.arrEmails) {
      if (!isValidEmail(email)) {
        return false
      }
    }

    return true
  }

  async onDelete(user: IUserWithPermission) {
    console.log(user)
    this.$q.notify({
      type: 'positive',
      message: 'Comming soon!',
    })
  }

  async onDeleteInvited(item: InvitationModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        await this.deleteInvitation(item._id || '')
        await this.getInvitedEmails()
      })
  }

  onSendInvitation() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.sendInvitation({
          role: this.form.role,
          emails: this.arrEmails,
        })

        this.form.emails = ''
        this.getInvitedEmails()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async created() {
    this.$meta.setMeta({ title: ['Members'] })
    await this.getWorkspace(this.workspaceId)
    await this.getInvitedEmails()
  }
}
</script>
